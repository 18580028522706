import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'

export const alphabetFilter = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  '#',
]

export const entitySettingsDefault = {
  id: 'ID0',
  floatIslandBG:
    'linear-gradient(248deg, rgba(255, 0, 0, 0.17) 1.52%, rgba(85, 105, 103, 0.50) 96.99%)',
  floatIslandOnListViewBG: '889494',
  offerCardColour2: 'AD9667',
  offerCardPrimaryBG: '556967',
  offerGridArrowColour: '556967',
  offerGridMasterHL: '',
  offerGridListViewBG: '53D0EE',
  visitCTA: '',
  visitURL: '#',
  visitTitle: {
    childMarkdownRemark: {
      html: '<br/>',
    },
  },
}

export const offersContextInitialState = {
  offers: [],
  groupedOffers: {},
  arrowProps: {},
  displayOffers: [],
  categories: [],
  selectedCategories: [],
  selectedLetter: '',
  displayOnlyTopOffers: false,
  displayCategories: false,
  displayList: false,
  mobileFrame: false,
  isMobile: false,
  floatingIslandEnabled: true,
  village: {},
  selectCategory: () => null,
  clearCategories: () => null,
  setDisplayCategories: () => null,
  toggleDisplayCategories: () => null,
  toggleDisplayAsList: () => null,
  toggleMobileFrame: () => null,
  toggleOnlyTopOffers: () => null,
  setFloatingIslandEnabled: () => null,
  toggleFloatingIslaneEnabled: () => null,
  selectLetter: () => null,
  clearAll: () => null,
  entitySettings: entitySettingsDefault,
}

export const OffersContextDefinition = createContext(offersContextInitialState)

const extractCategoriesFromOffers = (offers) => {
  if (offers.length < 1) return []

  const categoriesFound = []
  offers.forEach((offer) => {
    if (!!offer && offer?.brandLink?.categories) {
      offer.brandLink.categories.forEach(
        (category) =>
          !categoriesFound.some((filter) => filter.name === category.name) &&
          categoriesFound.push(category)
      )
    }
  })

  return categoriesFound
}

export const filterActiveOffers = (offers) => {
  if (offers.length < 1) return offers

  return offers.filter((offer) => {
    if (!offer?.offerRunFinishDate) return true
    return new Date(offer.offerRunFinishDate).getTime() >= Date.now()
  })
}

const resolveTopOffers = (offers, onlyTopOffers) =>
  !onlyTopOffers ? offers : offers.filter(({ topOffer }) => topOffer)

const applyFilter = (offers, filters) => {
  if (offers.length < 1 || filters.length < 1) return offers

  return offers.filter((offer) =>
    offer.brandLink.categories.some((category) =>
      filters.some((filter) => category.name === filter.name)
    )
  )
}

const hasFilterCategory = (name, categories) =>
  categories.filter((category) => category.name === name).length > 0

const groupByLetter = (offers) => {
  const groupedOffers = { ...offersContextInitialState.groupedOffers }

  if (offers.length < 1) return groupedOffers

  offers.forEach((offer) => {
    const brandName = offer?.brandLink?.name || ''
    const key = brandName[0].toUpperCase()
    if (brandName && /^[A-Z]$/.test(key)) {
      groupedOffers[key] = [...(groupedOffers[key] ?? []), offer]
    } else {
      groupedOffers['#'] = [...(groupedOffers['#'] ?? []), offer]
    }
  })

  return groupedOffers
}

const getSelectedGroup = (groupedOffers, selectedLetter) => {
  if (!selectedLetter) return groupedOffers

  return { [selectedLetter]: groupedOffers[selectedLetter] }
}

const OffersContext = ({
  children,
  offers = offersContextInitialState.offers,
  arrowProps = offersContextInitialState.arrowProps,
  categories,
  entitySettings,
  village,
}) => {
  const [displayOnlyTopOffers, setOnlyTopOffers] = useState(
    offersContextInitialState.displayOnlyTopOffers
  )
  const [displayCategories, setDisplayCategories] = useState(
    offersContextInitialState.displayCategories
  )
  const [displayList, setDisplayList] = useState(
    offersContextInitialState.displayList
  )
  const [mobileFrame, setMobileFrame] = useState(
    offersContextInitialState.mobileFrame
  )
  const [selectedLetter, setSelectedLetter] = useState(
    offersContextInitialState.selectedLetter
  )
  const [selectedCategories, setSelectedCategories] = useState(
    offersContextInitialState.selectedCategories
  )
  const [floatingIslandEnabled, setFloatingIslandEnabled] = useState(
    offersContextInitialState.floatingIslandEnabled
  )

  const toggleDisplayCategories = () =>
    setDisplayCategories((currentState) => !currentState)
  const toggleDisplayAsList = () =>
    setDisplayList((currentState) => !currentState)
  const toggleMobileFrame = () =>
    setMobileFrame((currentState) => !currentState)
  const toggleOnlyTopOffers = () =>
    setOnlyTopOffers((currentState) => !currentState)
  const selectCategory = (selectedCategory) =>
    selectedCategories.includes(selectedCategory)
      ? setSelectedCategories((currentState) =>
          currentState.toSpliced(currentState.indexOf(selectedCategory), 1)
        )
      : setSelectedCategories((currentState) => [
          ...currentState,
          selectedCategory,
        ])
  const clearCategories = () =>
    setSelectedCategories(() => offersContextInitialState.selectedCategories)
  const selectLetter = (letter) =>
    selectedLetter === letter
      ? setSelectedLetter('')
      : setSelectedLetter(letter)
  const toggleFloatingIslaneEnabled = () =>
    setFloatingIslandEnabled((currentState) => !currentState)

  const clearAll = () => {
    setOnlyTopOffers(() => offersContextInitialState.displayOnlyTopOffers)
    setSelectedCategories(() => offersContextInitialState.selectedCategories)
    setSelectedLetter(() => offersContextInitialState.selectedLetter)
  }

  const activeOffers = filterActiveOffers(offers)

  const categoriesFound = extractCategoriesFromOffers(activeOffers)
    .filter((item) => hasFilterCategory(item.name, categories))
    .sort(
      (a, b) =>
        categories.findIndex((cat) => cat.name === a.name) -
        categories.findIndex((cat) => cat.name === b.name)
    )

  const filteredOffers = applyFilter(
    resolveTopOffers(offers, displayOnlyTopOffers),
    selectedCategories
  )
  const groupedOffers = groupByLetter(filteredOffers)
  const displayOffers = getSelectedGroup(groupedOffers, selectedLetter)

  return (
    <OffersContextDefinition.Provider
      value={{
        offers:
          displayOffers.length < 1
            ? []
            : Object.values(displayOffers)
                .map((offersGroups) => offersGroups)
                .flat(),
        arrowProps,
        displayOffers,
        groupedOffers,
        categories: categoriesFound,
        displayOnlyTopOffers,
        selectedLetter,
        selectedCategories,
        displayCategories,
        displayList,
        mobileFrame,
        isMobile,
        floatingIslandEnabled,
        village,
        selectCategory,
        clearCategories,
        setDisplayCategories,
        toggleDisplayCategories,
        toggleDisplayAsList,
        toggleMobileFrame,
        toggleOnlyTopOffers,
        selectLetter,
        setFloatingIslandEnabled,
        toggleFloatingIslaneEnabled,
        clearAll,
        entitySettings,
      }}>
      {children}
    </OffersContextDefinition.Provider>
  )
}

OffersContext.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ).isRequired,
  offers: PropTypes.oneOfType([PropTypes.array]).isRequired,
  arrowProps: PropTypes.shape({
    color: PropTypes.string,
    position: PropTypes.string,
  }),
  entitySettings: PropTypes.shape({
    id: PropTypes.string,
    floatIslandBG: PropTypes.string,
    floatIslandOnListViewBG: PropTypes.string,
    offerCardColour2: PropTypes.string,
    offerCardPrimaryBG: PropTypes.string,
    offerGridArrowColour: PropTypes.string,
    offerGridMasterHL: PropTypes.string,
    offerGridListViewBG: PropTypes.string,
    visitCTA: PropTypes.string,
    visitURL: PropTypes.string,
    visitTitle: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string,
      }),
    }),
  }),
  village: PropTypes.shape({
    name: PropTypes.string,
  }),
}

OffersContext.defaultProps = {
  entitySettings: entitySettingsDefault,
  arrowProps: {
    color: '',
    marginTop: '',
  },
  village: {
    name: '',
  },
}

export default OffersContext
