import { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'

const useOfferAnimations = (offers, newOfferProps) => {
  const [animate, setAnimate] = useState(false)
  const [animate2, setAnimate2] = useState(false)
  const [animateOut, setAnimateOut] = useState(false)
  const [animateOut2, setAnimateOut2] = useState(false)
  const [updatedOffers, setUpdatedOffers] = useState([])
  const [previousId, setPreviousId] = useState('0')

  const isAnimationValid = (offer) => {
    if (!offer || previousId === offer.id) {
      return false
    }
    setPreviousId(offer.id)
    return true
  }

  const isFirstColumn = (index) =>
    isMobile ? [0, 2, 4, 6].includes(index) : [0, 1, 4, 5].includes(index)

  useEffect(() => {
    if (
      offers.length > 0 &&
      newOfferProps.showArrow &&
      isAnimationValid(offers[0])
    ) {
      setAnimate(true)
      const timer = setTimeout(() => {
        setAnimate(false)
      }, 2000)

      return () => clearTimeout(timer)
    }
    return () => {
      setAnimate(false)
    }
  }, [offers, newOfferProps.showArrow])

  useEffect(() => {
    if (updatedOffers.length > 0 && newOfferProps.showArrow) {
      setAnimate2(true)
      const timer = setTimeout(() => {
        setAnimate2(false)
      }, 2000)
      return () => clearTimeout(timer)
    }
    return () => setAnimate2(false)
  }, [updatedOffers, newOfferProps.showArrow])

  useEffect(() => {
    setAnimateOut2(false)
    if (!animate2 && newOfferProps.showArrow) {
      const timer = setTimeout(() => {
        setAnimateOut2(true)
      }, 2400)
      return () => clearTimeout(timer)
    }

    return () => {
      setAnimateOut2(false)
    }
  }, [animate2, newOfferProps.showArrow])

  useEffect(() => {
    setAnimateOut(false)
    if (!animate && newOfferProps.showArrow) {
      const timer = setTimeout(() => {
        setAnimateOut(true)
      }, 3000)
      return () => clearTimeout(timer)
    }

    return () => {
      setAnimateOut(false)
    }
  }, [animate, newOfferProps.showArrow])

  useEffect(() => {
    if (updatedOffers.length === 0) setUpdatedOffers(offers)
    const timer = setTimeout(() => {
      if (isAnimationValid(offers[0])) {
        offers.forEach((offer, index) => {
          if (!isFirstColumn(index)) {
            setUpdatedOffers((prev) => {
              const updatedOffer = [...prev]
              updatedOffer[index] = offer
              return updatedOffer
            })
          }
        })
      }
      return () => clearTimeout(timer)
    }, 800)
  }, [offers, newOfferProps.showArrow])

  return {
    animate,
    animate2,
    animateOut,
    animateOut2,
    updatedOffers,
    isFirstColumn,
  }
}

export default useOfferAnimations
