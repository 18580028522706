import { Title20 } from 'components/Typography'
import { rem } from 'polished'
import styled, { keyframes, css } from 'styled-components'
import theme from 'styles/theme'

const fadeInUp = keyframes`
  100% {
    opacity: 1;
    transform: translateY(0);
  }
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
`

// Animated styles with dynamic delay
const animatedStyles = css`
  animation: ${fadeInUp} 1s ease-in forwards;
`

export const VisitPageWrapper = styled.div`
  position:relative;
  flex-shrink: 0;
  opacity: 1;
  ${(props) =>
    props.animate &&
    css`
      ${animatedStyles};
      animation-delay: ${props.delay}s; /* Apply staggered delay */
    `}
    ${theme.mediaQueries.mobile} {
      margin-top: ${({ isCardSelected }) =>
        isCardSelected ? '100px;' : '0px;'};
`

export const VisitCardWrapper = styled.article`
  height: 371px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--Primary-Gold, ${(props) => props.colour});
  &:nth-child(odd) {
    margin-top: -80px;
  }
  &:nth-child(1),
  &:nth-child(3) {
    margin-top: -80px;
  }
  ${theme.mediaQueries.mobile} {
    padding: 51px 32.25px 59px 31.5px;
    align-self: stretch;
    height: 250px;
    &:nth-child(odd) {
      margin-top: -100px;
    }
    &:nth-child(1),
    &:nth-child(3) {
      margin-top: -100px;
    }
  }
  ${theme.mediaQueries.ipad} {
    height: 371px;
    &:nth-child(odd) {
      margin-top: -80px;
    }
    &:nth-child(1),
    &:nth-child(3) {
      margin-top: -80px;
    }
  }
`

export const VisitCardTextWrapper = styled(Title20)`
  color: #fff;
  text-align: center;
  ${theme.mediaQueries.mobile} {
    font-size: ${rem(12)};
  }
  ${theme.mediaQueries.ipad} {
    font-size: ${rem(18)};
  }
`

export const VisitCardSectionWrapper = styled.div`
  height: 40px; /* Adjust height to create the desired space */
`

export const VisitCardTitleWrapper = styled.div`
  color: var(--White-100, #fff);
  text-align: center;
  font-family: BrownStd;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
  ${theme.mediaQueries.mobile} {
    font-size: ${rem(12)};
  }
`
