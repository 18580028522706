import React from 'react'
import Layout from 'components/Layout'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import OffersContext, { entitySettingsDefault } from 'contexts/offers'
import useSmoothScroll from 'hooks/useSmoothScroll'
import Hero2 from 'components/Hero2'
import CampaignOfferHeader from 'components/CampaignOfferHeader/CampaignOfferHeader'
import OffersSection from 'components/OffersSection/OffersSection'
import { Spacing40, Spacing60 } from 'styles/sharedStyle'
import Arrow from 'components/Arrow/Arrow'
import { isMobile } from 'react-device-detect'
import OfferScrollSection from 'components/OffersSection/OffersScrollSection'
import ArrowSettingsIcon from 'components/Icons/ArrowSettingsIcon'
import constants from '../constants'

const CampaignOffersPageTemplateT18 = (props) => {
  useSmoothScroll()
  const { data, pageContext } = props
  const {
    page,
    settings: { nodes: settings },
    offersNodes: { nodes: offers },
  } = data
  const { pageLevel } = pageContext

  const {
    introText,
    termsAndConditions,
    subheadline,
    openStatus,
    brandLogo,
    closingDownCopy,
    hero,
    categories,
    village,
  } = page

  const isBrandStatusFlux =
    [
      constants.OPENING_STATUS,
      constants.OPEN_STATUS,
      constants.CLOSING_STATUS,
      constants.CLOSED_STATUS,
      constants.REOPENING_STATUS,
    ].indexOf(openStatus) > -1

  const media = hero && hero.media

  const hasHeroImage =
    (media && media.portrait && media.landscape) ||
    (media &&
      media.videoPlaceholder &&
      media.videoPlaceholder.landscape &&
      media.videoPlaceholder.portrait)

  let heroImage = {}

  if (hasHeroImage) {
    heroImage = {
      portrait: media.portrait || media.videoPlaceholder.portrait,
      landscape: media.landscape || media.videoPlaceholder.landscape,
      altText:
        media.altText ||
        (media.videoPlaceholder ? media.videoPlaceholder.altText : ''),
    }
  }

  const hasHeroVideo = media && media.videoPortrait && media.videoLandscape
  const heroVideo = hasHeroVideo
    ? {
        portrait: media.videoPortrait,
        landscape: media.videoLandscape,
        opacity: media.opacity,
      }
    : {}

  const heroProps = hero
    ? {
        ...hero,
        content: !!isBrandStatusFlux && closingDownCopy && (
          <span data-as="Title32">{closingDownCopy}</span>
        ),
        image: heroImage,
        logo: brandLogo,
        video: heroVideo,
        isCondensed: hero.isCondensed,
        opacity: hero.opacity,
        audiences: hero.audiences,
        villageSlug:
          pageLevel === 'collection'
            ? 'The Bicester Collection'
            : village?.name,
        village,
        titleSize: hero.titleSize ?? true,
        iconColor: `${
          !settings[0]?.offerGridArrowColour
            ? ''
            : `#${settings[0].offerGridArrowColour}`
        }`,
      }
    : null

  const arrowProps = {
    colour: settings ? `#${settings[0].offerGridArrowColour}` : '#556967',
    marginTop: undefined,
  }

  const headline = settings ? settings[0].offerGridMasterHL : ''

  const visitCardProps = {
    text: settings ? settings[0].visitTitle.childMarkdownRemark.html : '',
    title: settings ? `${settings[0].visitCTA}` : 'PLAN YOUR VISIT',
    colour: settings ? `#${settings[0].offerCardColour2}` : '#AD9667',
  }

  const redirectToOffers = () => {
    const offersElement = document.getElementById('campaign-text')
    if (offersElement) {
      offersElement.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const currentOffers = OfferScrollSection(
    offers.sort(
      (a, b) => new Date(a.offerRunFinishDate) - new Date(b.offerRunFinishDate)
    ),
    isMobile
  )

  return (
    <Layout {...props}>
      <Helmet />
      <Hero2 {...heroProps} />
      <Arrow
        {...arrowProps}
        onClick={() => redirectToOffers()}
        icon={<ArrowSettingsIcon transform="rotate(270deg)" />}
      />
      <Spacing60 />
      <CampaignOfferHeader
        key="campaing-header"
        headline={headline}
        introText={introText}
        offerLength={offers?.length}
        subheadline={subheadline}
        termsAndConditions={termsAndConditions}
      />
      {!isMobile && (
        <>
          <Spacing40 />
          <Spacing40 />
        </>
      )}
      <OffersContext
        offers={currentOffers.currentOffers}
        entitySettings={settings[0] ?? entitySettingsDefault}
        arrowProps={arrowProps}
        categories={categories}
        village={village}>
        <OffersSection
          offers={currentOffers.currentOffers}
          gridProps={{
            heroCTA1: hero.cta1,
            heroCTA1Link: hero.cta1Link,
            visitCardProps,
            settings,
            village,
            nodeLocale: pageContext.nodeLocale,
            newOfferProps: currentOffers.newOfferProps,
          }}
          showFloatingIsland={currentOffers.showFloatingIsland}
          islandMethods={currentOffers.islandMethods}
        />
      </OffersContext>
    </Layout>
  )
}

CampaignOffersPageTemplateT18.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageContext: PropTypes.shape({
    nodeLocale: PropTypes.string.isRequired,
    pageLevel: PropTypes.string.isRequired,
  }).isRequired,
}

export const campaignOffersPageTemplate = graphql`
  query(
    $id: String!
    $openingHoursId: String
    $dateStart: Date!
    $dateEnd: Date!
    $dateNow: Date!
    $nodeLocale: String!
    $offersIds: [String!]!
  ) {
    page: contentfulPageTemplateOffersCampaignT18(id: { eq: $id }) {
      id
      contentful_id
      instanceName
      categories {
        name
      }
      introText {
        childMarkdownRemark {
          html
        }
      }
      subheadline
      termsAndConditions
      village {
        name
        villageSlug: slug
        currency
        home: page_template_home_t01 {
          ...headerFooterVillage
        }
        openingHours {
          ...villageOpeningHours
        }
        openingStatusLabel
        labels: label_brand_details_lab01 {
          closedLabel
          closingLabel
          openingLabel
          reopeningLabel
        }
        defaultLocale
        villagePlaceholderImage: image {
          __typename
          ... on Node {
            ... on ContentfulEntityImageEnt02 {
              ...ResponsiveImageQuery
            }
          }
        }
        brands: page_template_brand_detail_t06 {
          name
          slug
          hideBrand
          openStatus: flags
          categories {
            name
            label
          }
        }
      }
      hero {
        ...hero2MediaQuery
      }
    }
    offersNodes: allContentfulCompOfferCard01(
      sort: { fields: [offerRunFinishDate, offerTitle], order: [ASC, ASC] }
      filter: { id: { in: $offersIds }, offerRunFinishDate: { gte: $dateNow } }
    ) {
      nodes {
        ...newOfferCardQuery
      }
    }
    openingHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_village_oph01: {
          elemMatch: { id: { eq: $openingHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          ...contentfulOpeningHoursExceptions
        }
      }
    }
    openingHoursLabels: allContentfulLabelOpeningHoursLab04(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          ...contentfulOpeningHoursLabels
        }
      }
    }
    settings: allContentfulEntityCampaignTemplateSettings(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      nodes {
        id
        floatIslandBG
        floatIslandOnListViewBG
        offerCardColour2
        offerCardPrimaryBG
        offerGridArrowColour
        offerGridMasterHL
        offerGridListViewBG
        updatedAt
        visitCTA
        visitURL
        visitTitle {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`

export default CampaignOffersPageTemplateT18
