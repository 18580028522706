import React, { useEffect, useRef } from 'react'
import { Spacing16, Spacing20, Spacing40 } from 'styles/sharedStyle'
import { formatHeroDate } from 'utils/date'
import { useComponentId, useFieldId } from 'utils/optimizely'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import {
  ButtonWrapper,
  MembersOnly,
  OfferBrand,
  OfferContainer,
  OfferDates,
  OfferTerms,
  Button,
  OfferTitle,
  PhantonButton,
  WrapperMobile,
  OfferSubtitle,
  OfferDetails,
  GridPlacement,
  Closer,
  CustomSpacing,
} from 'components/NewOfferCardDetail/NewOfferCardDetail.styles'
import Link from 'components/Link'

const NewOfferCardDetailMobile = ({
  gridRow,
  offer,
  settings,
  close,
  village,
  nodeLocale,
}) => {
  const {
    offerRunFinishDate,
    offerRunStartDate,
    membersOnly,
    brandLink,
    offerSubtitle,
    description,
    offerTitle,
    legalTerms,
    signInSignUp,
  } = offer
  const campaignDateContent = `${formatHeroDate(
    offerRunStartDate
  )} - ${formatHeroDate(offerRunFinishDate)} ${new Date(
    offerRunFinishDate
  ).getFullYear()}`
  const showSigninGroup = membersOnly && signInSignUp

  const planYourVisitCTA = settings[0].visitCTA
  const planYourVisitLINK = settings[0].visitURL
  const elementId = useComponentId(`Comp_Offer_Card_Detail`)
  function onCTAClick(to) {
    navigate(to)
  }
  const backgroundColor = settings[0].offerCardPrimaryBG
  const invertedColor = 'fff'

  const wrapperRef = useRef(null)

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [wrapperRef.current, offer.id])

  return (
    isMobile && (
      <GridPlacement gridRow={gridRow}>
        <WrapperMobile ref={wrapperRef}>
          <OfferContainer background={settings[0].offerCardPrimaryBG}>
            <Closer
              hexcode={`#${invertedColor}`}
              color={`#${backgroundColor}`}
              onClick={close}
            />
            {membersOnly && <MembersOnly>Members Only</MembersOnly>}
            <Spacing16 />
            <OfferDates>{campaignDateContent}</OfferDates>
            <CustomSpacing />
            <OfferBrand>
              <Link
                to={`${village.villageSlug}/${nodeLocale}/${brandLink.pageType.slug}/${brandLink.slug}`}>
                {brandLink.name}
              </Link>
            </OfferBrand>
            <Spacing40 />
            {offerTitle && (
              <OfferTitle color={invertedColor}>{offerTitle}</OfferTitle>
            )}
            {offerSubtitle && <OfferSubtitle>{offerSubtitle}</OfferSubtitle>}
            {description && (
              <OfferDetails
                dangerouslySetInnerHTML={{
                  __html: description.childMarkdownRemark.html,
                }}
              />
            )}
            {showSigninGroup && (
              <ButtonWrapper>
                {signInSignUp.signupLabel && (
                  <Button
                    color={invertedColor}
                    backgroundColor={backgroundColor}
                    id={useFieldId(elementId, `register-cta`)}
                    onClick={() => onCTAClick(signInSignUp.signupUrl)}>
                    {signInSignUp.signupLabel}
                  </Button>
                )}
                <PhantonButton
                  color={invertedColor}
                  id={useFieldId(elementId, `singin-cta`)}
                  level="quaternary"
                  onClick={() => onCTAClick(planYourVisitLINK)}>
                  {planYourVisitCTA}
                </PhantonButton>
              </ButtonWrapper>
            )}

            <Spacing20 />
            {legalTerms && <OfferTerms>{legalTerms}</OfferTerms>}
          </OfferContainer>
        </WrapperMobile>
      </GridPlacement>
    )
  )
}

NewOfferCardDetailMobile.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.string,
    offerTitle: PropTypes.string,
    membersOnly: PropTypes.bool,
    offerRunFinishDate: PropTypes.string,
    offerRunStartDate: PropTypes.string,
    brandLink: PropTypes.string,
    offerSubtitle: PropTypes.string,
    isModalOpen: PropTypes.bool,
    description: PropTypes.string,
    heroCTA1: PropTypes.string,
    heroCTA1Link: PropTypes.string,
    legalTerms: PropTypes.string,
    signInSignUp: PropTypes.shape({
      signinLabel: PropTypes.string,
      signupLabel: PropTypes.string,
      signinUrl: PropTypes.string,
      signupUrl: PropTypes.string,
    }),
  }),
  settings: PropTypes.shape({
    floatIslandBG: PropTypes.string,
    id: PropTypes.string,
    offerCardColour2: PropTypes.string,
    offerCardPrimaryBG: PropTypes.string,
    offerGridArrowColour: PropTypes.string,
    offerGridListViewBG: PropTypes.string,
    offerGridMasterHL: PropTypes.string,
    updatedAt: PropTypes.string,
    visitCTA: PropTypes.string,
    visitTitle: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string,
      }),
    }),
    visitURL: PropTypes.string,
  }),
  gridRow: PropTypes.number,
  close: PropTypes.func,
  village: PropTypes.shape({
    villageSlug: PropTypes.string,
  }),
  nodeLocale: PropTypes.string,
}

NewOfferCardDetailMobile.defaultProps = {
  offer: {
    offerId: '1',
    offerTitle: '',
    membersOnly: false,
    offerRunFinishDate: '',
    offerRunStartDate: '',
    brandLink: '',
    offerSubtitle: '',
    isModalOpen: false,
    description: '',
    heroCTA1: '',
    heroCTA1Link: '',
    legalTerms: '',
    signInSignUp: {
      signinLabel: '',
      signupLabel: '',
      signinUrl: '',
      signupUrl: '',
    },
  },
  gridRow: 0,
  settings: {
    floatIslandBG: '',
    id: '',
    offerCardColour2: '',
    offerCardPrimaryBG: '',
    offerGridArrowColour: '',
    offerGridListViewBG: '',
    offerGridMasterHL: '',
    updatedAt: '',
    visitCTA: '',
    visitTitle: {
      childMarkdownRemark: {
        html: '',
      },
    },
    visitURL: '',
  },
  close: () => {},
  village: {
    villageSlug: '',
  },
  nodeLocale: 'en',
}

export default NewOfferCardDetailMobile
