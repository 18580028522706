// Date utils
/**
 * Formats a date into DD/MM/YYYY HH:MM.
 * @returns {string} The formatted date string.
 */
export default function formatDate() {
  const d = new Date()
  const year = d.getFullYear()
  const month = `0${d.getMonth() + 1}`.slice(-2)
  const day = `0${d.getDate()}`.slice(-2)
  const hours = `0${d.getHours()}`.slice(-2)
  const minutes = `0${d.getMinutes()}`.slice(-2)
  return `${day}/${month}/${year} ${hours}:${minutes}`
}

export function getMonthName(date = '') {
  return new Date(date).toLocaleString('default', { month: 'long' })
}

export function formatHeroDate(date) {
  const dt = new Date(date)
  return `${dt.getDate()} ${getMonthName(date)}`
}
