import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Content } from './FloatingIsland.style'

const FloatingIsland = ({ children, scrollingTop, hide, mt }) => {
  return (
    <Wrapper scrollingTop={scrollingTop} hide={hide}>
      <Content mt={mt}>{children}</Content>
    </Wrapper>
  )
}

FloatingIsland.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  scrollingTop: PropTypes.bool,
  hide: PropTypes.bool,
  mt: PropTypes.string,
}

FloatingIsland.defaultProps = {
  scrollingTop: false,
  hide: false,
  mt: '',
}

export default FloatingIsland
