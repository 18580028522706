import React from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import { display, background } from 'styled-system'
import { GatsbyImage } from 'gatsby-plugin-image'
import CalendarIconBase from 'components/Icons/CalendarIcon'
import { Body20, Title26, Title60, Title70 } from 'components/Typography'
import { Spacing38 as BaseSpacing38 } from 'styles/sharedStyle'
import DefaultButton from 'components/Button'
import MarkdownRenderer from 'components/MarkdownRenderer'
import { appearingAnimation } from 'styles/animation'
import theme from 'styles/theme'

const animationProps = `
  animation-direction: normal;
  animation-duration: 1.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(.54,0,.15,1);
`

export const HeroWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin: 0 auto;
  height: 86.5vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;

  ${(props) => !props.hasImage && `background-color: ${theme.colors.coaldark};`}

  a {
    text-decoration: none;
  }
`
export const HeroImg = styled(GatsbyImage)`
  ${display}
`
export const HeroImage = styled.div`
  ${display}
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;

  ::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 65%;
    min-height: 25vh;
    z-index: 1;
    background: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #111 100%);
  }

  .gatsby-image-wrapper {
    height: calc(100% + 3px);
  }

  ::after {
    ${background}
  }

  ${({ showOverlay }) =>
    showOverlay &&
    `
    ::after {
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      display: block;
    }
  `};

  ${({ variant }) =>
    variant === 'memDays' &&
    `
    bottom: 0;
    height: 114%;
    top: auto;

    ${theme.mediaQueries.medium} {
      bottom: auto;
      height: 200%;
      top: calc(-100% + 300px);
    }
  `}
`

export const HeroCta = styled.div`
  display: flex;
  flex-flow: row no-wrap;
  column-gap: 0.25rem;
  z-index: 100;
  position: relative;
  opacity: 0;
  margin: 0 auto;
  text-align: center;
  animation: ${appearingAnimation('150px')} .3s ease-out;
  ${animationProps}
  animation-delay: ${(props) => props.delay};

  > * {
    flex: 1;

    ${theme.mediaQueries.medium} {
      flex: initial;
    }
  }
  
`

export const HeroBody = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: flex-start;
  min-height: 43%;
  width: 100%;
  margin-bottom: ${rem(64)};
  padding: 0 ${rem(16)};
  color: ${theme.colors.white};

  ${theme.mediaQueries.medium} {
    padding: 0 ${rem(56)};
    margin-bottom: ${theme.space[10]};
  }

  ${theme.mediaQueries.large} {
    margin-bottom: ${theme.space[10]};
  }

  ${theme.mediaQueries.xlarge} {
    margin-bottom: ${theme.space[10]};
  }

  animation: ${appearingAnimation('150px')} .3s ease-out;
  ${animationProps}
  animation-delay: ${(props) => props.delay};
`

export const PreTitle = styled(Title26)`
  font-family: SangbleuKindgdom-Light, serif;
  font-weight: 300;
  color: white;
  line-height: 1;
  font-size: ${rem(20)};

  ${theme.mediaQueries.large} {
    font-size: ${rem(30)};
  }
`

export const Title = styled(Title70)`
  font-family: SangbleuKindgdom-Light, serif;
  font-weight: 300;
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => (props.isMediumTitle ? rem(42) : rem(70))};

  ${theme.mediaQueries.large} {
    font-size: ${(props) => (props.isMediumTitle ? rem(70) : rem(140))};
  }
`

export const Subtitle = styled(Title60)`
  font-family: SangbleuKindgdom-Light, serif;
  font-weight: 300;
  color: white;
  font-size: ${rem(42)};
  margin-top: -${rem(10)};
  ${theme.mediaQueries.large} {
    font-size: ${rem(70)};
  }
`

export const Subcopy = styled((props) => (
  <MarkdownRenderer {...props} as="div" html={props.content} />
))`
  font-family: BrownStd-Light;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  font-weight: 300;
  p {
    font-family: BrownStd-Light;
    font-size: ${rem(20)};
  }

  ${theme.mediaQueries.large} {
    p {
      font-family: BrownStd-Light;
      font-size: ${rem(30)};
    }
  }

  * {
    margin-bottom: initial;
  }
`

export const CampaignDateWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  column-gap: ${rem(9.6)};
  align-items: center;
  animation: ${appearingAnimation('150px')} 0.3s ease-out;
  ${animationProps}

  ${theme.mediaQueries.medium} {
    column-gap: ${rem(26)};
  }
`

export const CalendarIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: ${rem(5)};
  border-radius: 2rem;
  background-color: white;
  border: 1px solid white;

  ${theme.mediaQueries.medium} {
    padding: ${rem(7.5)};
  }
`

export const CalendarIcon = styled(CalendarIconBase)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: ${rem(22)};
  height: ${rem(22)};
  padding-left: ${rem(1.3)};

  html[dir='ltr'] & {
    right: calc(${theme.space[10]} / -2);
  }

  html[dir='rtl'] & {
    left: calc(${theme.space[10]} / -2);
  }

  ${theme.mediaQueries.medium} {
    width: ${rem(30.6)};
    height: ${rem(30.6)};
    padding-left: ${rem(1.9)};
  }
`

export const CampaignDate = styled(Body20)`
  display: block;
  font-family: BrownStd-light, serif;
  font-weight: 400;
  color: ${theme.colors.white};
  position: relative;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: ${rem(20)};

  ${theme.mediaQueries.large} {
    font-family: BrownStd-Light, serif;
    font-size: ${rem(30)};
    font-weight: 300;
    text-transform: initial;
  }
`

export const Button = styled(DefaultButton)`
  text-decoration: none;
  height: initial;
  padding: 1rem 2rem;
  font-size: ${rem(19.2)};

  span {
    font-family: BrownStd-Light, serif;
    line-height: initial;
    font-size: inherit;
  }

  ${theme.mediaQueries.medium} {
    padding: ${rem(18)} ${rem(83)};
    font-size: ${rem(16)};
  }
`

export const PhantonButton = styled((props) => (
  <DefaultButton level="quaternary" {...props} />
))`
  text-decoration: none;
  height: initial;
  border-color: white;
  color: white;
  padding: 1rem 2rem;
  font-size: ${rem(19.2)};

  span {
    font-family: BrownStd-Light, serif;
    line-height: initial;
    font-size: inherit;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background: ${theme.colors.sagedark};
    border-color: ${theme.colors.sagedark};
  }

  ${theme.mediaQueries.medium} {
    padding: ${rem(18)} ${rem(83)};
    font-size: ${rem(16)};
  }
`

export const HeroColumn = styled.div`
  width: 100%:;
  display: flex;
  flex-flow: column nowrap;
`

export const HeroRow = styled.div`
  width: 100%;
  display: flex;
  ${(props) =>
    props?.reverseOnMobile
      ? 'flex-flow: column-reverse nowrap;'
      : 'flex-flow: column nowrap;'}
  justify-content: space-between;

  ${theme.mediaQueries.medium} {
    flex-flow: row nowrap;
    align-items: flex-end;
  }
`

export const Spacing38 = styled(BaseSpacing38)`
  ${theme.mediaQueries.medium} {
    display: none;
  }
`

export default {
  HeroRow,
  HeroColumn,
  HeroWrapper,
  HeroImage,
  PreTitle,
  Title,
  Subtitle,
  CampaignDateWrapper,
  CalendarIconWrapper,
  CalendarIcon,
  CampaignDate,
  Subcopy,
  Button,
  PhantonButton,
  Spacing38,
}
