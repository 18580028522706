import React, { useEffect, useRef } from 'react'
import { navigate } from 'gatsby'
import { formatHeroDate } from 'utils/date'
import Link from 'components/Link'
import { Spacing16, Spacing40, Spacing50 } from 'styles/sharedStyle'
import { useComponentId, useFieldId } from 'utils/optimizely'
import PropTypes from 'prop-types'
import {
  Wrapper,
  OfferBorderContainer,
  OfferContainer,
  MembersOnly,
  OfferDates,
  OfferBrand,
  OfferTitle,
  ButtonWrapper,
  Button,
  PhantonButton,
  OfferDetails,
  OfferSubtitle,
  OfferTerms,
  Closer,
  CustomSpacing,
} from './NewOfferCardDetail.styles'

/**
 * Hook that closes the card on click outside of the passed ref
 */
function useOutsideAlerter(ref, close) {
  useEffect(() => {
    /**
     * Close if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        close()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

const NewOfferCardDetail = ({
  offerTitle,
  membersOnly,
  offerRunFinishDate,
  offerRunStartDate,
  brandLink,
  offerSubtitle,
  isModalOpen,
  description,
  close,
  legalTerms,
  signInSignUp,
  settings,
  inverted,
  village,
  nodeLocale,
  islandMethods,
  id: offerId,
}) => {
  const campaignDateContent = `${formatHeroDate(
    offerRunStartDate
  )} - ${formatHeroDate(offerRunFinishDate)} ${new Date(
    offerRunFinishDate
  ).getFullYear()}`
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, () => {
    close()
    if (islandMethods && islandMethods.openFloatingIsland) {
      islandMethods.openFloatingIsland()
    }
  })
  const elementId = useComponentId(`Comp_Offer_Card_Detail`)
  const onCTAClick = (to) => navigate(to)
  const showSigninGroup = membersOnly && signInSignUp

  const planYourVisitCTA = settings[0].visitCTA
  const planYourVisitLINK = settings[0].visitURL

  const backgroundColor = inverted ? 'fff' : settings[0].offerCardPrimaryBG
  const invertedColor = inverted ? settings[0].offerCardPrimaryBG : 'fff'

  useEffect(() => {
    if (isModalOpen && wrapperRef.current) {
      wrapperRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [isModalOpen, wrapperRef.current, offerId])

  useEffect(() => {
    if (isModalOpen && islandMethods) {
      islandMethods.hideFloatingIsland()
    } else if (islandMethods) {
      islandMethods.openFloatingIsland()
    }
  }, [isModalOpen])

  return isModalOpen ? (
    <Wrapper showing={isModalOpen}>
      <OfferBorderContainer ref={wrapperRef}>
        <OfferContainer
          background={backgroundColor}
          invertedColor={invertedColor}>
          <Closer
            hexcode={`#${invertedColor}`}
            color={`#${backgroundColor}`}
            onClick={() => {
              close()
              if (islandMethods && islandMethods.openFloatingIsland) {
                islandMethods.openFloatingIsland()
              }
            }}
          />

          {membersOnly && <MembersOnly>Members Only</MembersOnly>}
          <Spacing16 />
          <OfferDates>{campaignDateContent}</OfferDates>
          <CustomSpacing />
          <OfferBrand>
            <Link
              to={`${village.villageSlug}/${nodeLocale}/${brandLink.pageType.slug}/${brandLink.slug}`}>
              {brandLink.name}
            </Link>
          </OfferBrand>
          <Spacing40 />
          {offerTitle && (
            <OfferTitle color={invertedColor}>{offerTitle}</OfferTitle>
          )}
          {offerSubtitle && <OfferSubtitle>{offerSubtitle}</OfferSubtitle>}
          {description && (
            <OfferDetails
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html,
              }}
            />
          )}
          {showSigninGroup && (
            <ButtonWrapper>
              {signInSignUp.signupLabel && (
                <Button
                  color={invertedColor}
                  backgroundColor={backgroundColor}
                  id={useFieldId(elementId, `register-cta`)}
                  onClick={() => onCTAClick(signInSignUp.signupUrl)}>
                  {signInSignUp.signupLabel}
                </Button>
              )}
              <PhantonButton
                color={invertedColor}
                id={useFieldId(elementId, `singin-cta`)}
                level="quaternary"
                onClick={() => onCTAClick(planYourVisitLINK)}>
                {planYourVisitCTA}
              </PhantonButton>
            </ButtonWrapper>
          )}

          {legalTerms && (
            <>
              <Spacing50 />
              <OfferTerms color={invertedColor}>{legalTerms}</OfferTerms>
            </>
          )}
        </OfferContainer>
      </OfferBorderContainer>
    </Wrapper>
  ) : null
}

NewOfferCardDetail.propTypes = {
  id: PropTypes.string,
  offerTitle: PropTypes.string,
  membersOnly: PropTypes.bool,
  offerRunFinishDate: PropTypes.string,
  offerRunStartDate: PropTypes.string,
  brandLink: PropTypes.string,
  offerSubtitle: PropTypes.string,
  isModalOpen: PropTypes.bool,
  description: PropTypes.string,
  close: PropTypes.func,
  legalTerms: PropTypes.string,
  signInSignUp: PropTypes.shape({
    signinLabel: PropTypes.string,
    signinUrl: PropTypes.string,
    signupLabel: PropTypes.string,
    signupUrl: PropTypes.string,
  }),
  settings: PropTypes.shape({
    floatIslandBG: PropTypes.string,
    id: PropTypes.string,
    offerCardColour2: PropTypes.string,
    offerCardPrimaryBG: PropTypes.string,
    offerGridArrowColour: PropTypes.string,
    offerGridListViewBG: PropTypes.string,
    offerGridMasterHL: PropTypes.string,
    updatedAt: PropTypes.string,
    visitCTA: PropTypes.string,
    visitTitle: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string,
      }),
    }),
    visitURL: PropTypes.string,
  }),
  inverted: PropTypes.bool,
  village: PropTypes.shape({
    villageSlug: PropTypes.string,
  }),
  nodeLocale: PropTypes.string,
  islandMethods: PropTypes.shape({
    hideFloatingIsland: PropTypes.func,
    openFloatingIsland: PropTypes.func,
  }).isRequired,
}

NewOfferCardDetail.defaultProps = {
  id: '0',
  offerTitle: '',
  membersOnly: false,
  offerRunFinishDate: '',
  offerRunStartDate: '',
  brandLink: '',
  offerSubtitle: '',
  isModalOpen: false,
  description: '',
  close: () => {},
  legalTerms: '',
  signInSignUp: {
    signinLabel: '',
    signinUrl: '',
    signupLabel: '',
    signupUrl: '',
  },
  settings: {
    floatIslandBG: '',
    id: '',
    offerCardColour2: '',
    offerCardPrimaryBG: '',
    offerGridArrowColour: '',
    offerGridListViewBG: '',
    offerGridMasterHL: '',
    updatedAt: '',
    visitCTA: '',
    visitTitle: {
      childMarkdownRemark: {
        html: '',
      },
    },
    visitURL: '',
  },
  inverted: false,
  village: {
    villageSlug: '',
  },
  nodeLocale: 'en',
}

export default NewOfferCardDetail
