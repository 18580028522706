import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { alphabetFilter, OffersContextDefinition } from 'contexts/offers'
import {
  MobileCharacterBoxWrapper,
  MobileCharacterWrapper,
  MobileFrameKeyBoardWrapper,
  MobileFrameWrapper as StyledMobileFrameWrapper,
} from './MobileFrame.style'

const MobileFrame = ({ isVisible, bg, buttonBg }) => {
  const [animateIn, setAnimateIn] = useState(false)
  const { groupedOffers, selectedLetter, selectLetter } = useContext(
    OffersContextDefinition
  )

  useEffect(() => {
    if (isVisible) {
      setAnimateIn(true)
    }
  }, [isVisible])

  const onAnimationEnd = () => {
    if (!isVisible) {
      setAnimateIn(false)
    }
  }

  return (
    <StyledMobileFrameWrapper
      isVisible={isVisible}
      animateIn={animateIn}
      onAnimationEnd={onAnimationEnd}
      bg={bg}>
      <MobileFrameKeyBoardWrapper>
        {alphabetFilter.map((letter) => {
          const isDisabled = !Object.prototype.hasOwnProperty.call(
            groupedOffers,
            letter
          )
          const isActive = selectedLetter === letter

          return (
            <MobileCharacterBoxWrapper
              key={`mobileAlphabet-${letter}`}
              on={isActive}
              disabled={isDisabled}
              onClick={() => selectLetter(letter)}
              bg={buttonBg}>
              <MobileCharacterWrapper>{letter}</MobileCharacterWrapper>
            </MobileCharacterBoxWrapper>
          )
        })}
      </MobileFrameKeyBoardWrapper>
    </StyledMobileFrameWrapper>
  )
}

MobileFrame.propTypes = {
  isVisible: PropTypes.bool,
  bg: PropTypes.string,
  buttonBg: PropTypes.string,
}

MobileFrame.defaultProps = {
  isVisible: false,
  bg: '',
  buttonBg: '',
}

export default MobileFrame
