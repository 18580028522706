import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { css } from 'styled-components'
import { OffersContextDefinition } from 'contexts/offers'
import { Spacing30 } from 'styles/sharedStyle'
import { appearingAnimation } from 'styles/animation'
import CheckIcon from 'components/Icons/CheckIcon'
import CloseIcon from 'components/Icons/CloseIcon'
import {
  Container,
  Divisor,
  PhatonButton,
  IslandRow,
} from 'components/FloatingIsland/FloatingIsland.style'
import {
  OfferCategoryGrid,
  CategoryFilterButton,
  CategoryInternalContainer,
  FilterCategoryName,
  OffersCategoriesFilterWrapper,
} from './OffersSection.style'

const OffersCategoriesFilter = ({ stickToBottom }) => {
  const {
    categories,
    selectedCategories,
    displayList,
    isMobile,
    selectCategory,
    toggleDisplayCategories,
    clearCategories,
    entitySettings: { floatIslandBG, floatIslandOnListViewBG },
  } = useContext(OffersContextDefinition)

  return (
    <OffersCategoriesFilterWrapper stickToBottom={stickToBottom}>
      {!stickToBottom && <Divisor />}
      <Container
        bg={displayList ? `#${floatIslandOnListViewBG}` : floatIslandBG}
        animation={css`
          ${appearingAnimation('30px')} 0.75s
        `}>
        <CategoryInternalContainer>
          <IslandRow>
            <PhatonButton onClick={clearCategories}>Clear</PhatonButton>
            <PhatonButton onClick={toggleDisplayCategories}>
              <CloseIcon width="22px" />
            </PhatonButton>
          </IslandRow>
          {!isMobile && <Spacing30 />}
          <OfferCategoryGrid>
            {categories.map((category) => {
              const isActive = selectedCategories.includes(category)
              return (
                <CategoryFilterButton
                  on={isActive}
                  onClick={() => selectCategory(category)}>
                  <FilterCategoryName>{category.label}</FilterCategoryName>
                  {isActive && <CheckIcon width="18px" />}
                </CategoryFilterButton>
              )
            })}
          </OfferCategoryGrid>
        </CategoryInternalContainer>
      </Container>
    </OffersCategoriesFilterWrapper>
  )
}

OffersCategoriesFilter.propTypes = {
  stickToBottom: PropTypes.bool,
}

OffersCategoriesFilter.defaultProps = {
  stickToBottom: false,
}

export default OffersCategoriesFilter
