import React from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import { isMobile } from 'react-device-detect'
import {
  BottomWhiteCurvedEdge,
  Spacing16 as BaseSpacing16,
  Spacing48,
  Spacing38,
} from 'styles/sharedStyle'
import animationUtils from 'utils/animation'
import { useComponentId, useFieldId } from 'utils/optimizely'
import VideoPlayer from 'components/VideoPlayer'
import videoPlayerUtils from 'components/VideoPlayer/videoPlayerUtils'
import Parallax from 'components/Parallax/Parallax'
import GA4 from 'utils/GA4'
import { OPACITIES } from '../../constants'
import { fixAudiences } from '../../utils/audiences'
import {
  HeroRow,
  HeroBody,
  HeroCta,
  HeroImg,
  HeroImage,
  HeroWrapper,
  PreTitle,
  Title,
  Subtitle,
  Subcopy,
  Button,
  PhantonButton,
} from './Hero2.style'
import Hero2CampaignDate from './Hero2CampaignDate'

const parallaxConfig = {
  scrollThrottleAmount: 0,
  speed: 0.5,
  extraStyle: `
    width: 100%;
    height: 100%;
    position: relative;
  `,
}

const Hero2 = (props) => {
  const {
    audiences,
    opacity,
    content,
    ctaLabel,
    ctaUrl,
    eyebrow,
    image,
    isBrandDirectory,
    logo,
    pageTemplate,
    toggleBrandSearch,
    video,
    villageSlug,
    village,
    campaignSubtitle,
    datePickerFrom,
    datePickerTo,
    preTitle,
    title,
    titleSize,
    registerCTA,
    registerCTALink,
    signInCTA,
    signInCTALink,
    subcopy,
    iconColor,
  } = props

  const { landscape, portrait, altText } = image

  const variant =
    {
      ContentfulPageTemplateMemorableDaysT14: 'memDays',
    }[pageTemplate] || 'default'

  const delayObj = animationUtils.calculateDelay({
    logo,
    eyebrow,
    content,
    cta: ctaLabel && (ctaUrl || (isBrandDirectory && toggleBrandSearch)),
  })

  const overlayOpacity = opacity ? OPACITIES[opacity] : '0.15'
  const background = `rgba(0, 0, 0, ${overlayOpacity});`

  const hasImage =
    portrait &&
    portrait.gatsbyImageData &&
    landscape &&
    landscape.gatsbyImageData
  const heroImageProps = {
    showOverlay:
      (content || eyebrow || logo) && hasImage && variant !== 'memDays',
    variant,
    background,
  }

  const hasVideo = video && video.portrait && video.landscape

  if (hasVideo) {
    // eslint-disable-next-line no-param-reassign
    video.opacity = videoPlayerUtils.videoPlayerHeroOpacityOverride(
      video,
      overlayOpacity
    )
  }

  const hasAudiences = !!audiences
  const hasCTA =
    (registerCTA && registerCTALink) || (signInCTA && signInCTALink)
  const onHeroCTAClick = (text, to) => {
    navigate(to)
    const eventName = text
      .toLowerCase()
      .replace(/\s+/g, '')
      .includes('singin')
      ? 'sign_in_camp_hero'
      : 'sign_up_camp_hero'
    GA4(eventName, {
      destination_url: to,
      village_name: village.name,
    })
  }
  const elementId = useComponentId(`Comp_camp_hero_HER02`)
  const mobileBgId = `${elementId}_bg_mobile`
  const desktopBgId = `${elementId}_bg_desktop`

  return (
    <HeroWrapper
      id={elementId}
      variant={variant}
      className={fixAudiences(audiences)}
      hasAudience={hasAudiences}
      hasImage={hasImage}>
      <HeroImage {...heroImageProps}>
        {hasVideo && (
          <VideoPlayer
            id={useFieldId(elementId, `video`)}
            name={video.name || content}
            controls={false}
            loop
            muted
            video={video}
            autoplay
            image={{ portrait, landscape, altText }}
            noLazyload
            villageSlug={villageSlug}
          />
        )}
        {hasImage && !hasVideo && (
          <Parallax {...parallaxConfig}>
            <HeroImg
              id={mobileBgId}
              image={portrait.gatsbyImageData}
              display={['block', null, 'none']}
              alt={altText}
              loading="eager"
            />
            <HeroImg
              id={desktopBgId}
              image={landscape.gatsbyImageData}
              display={['none', null, 'block']}
              alt={altText}
              loading="eager"
            />
          </Parallax>
        )}
      </HeroImage>

      <HeroBody delay={delayObj.content}>
        {preTitle && (
          <>
            <PreTitle>{preTitle}</PreTitle>
            <BaseSpacing16 />
          </>
        )}
        {title && (
          <Title isMediumTitle={titleSize} id={useFieldId(elementId, `title`)}>
            {title}
          </Title>
        )}
        {campaignSubtitle && <Subtitle>{campaignSubtitle}</Subtitle>}
        {subcopy && (
          <HeroRow reverseOnMobile>
            <div>
              <BaseSpacing16 />
              <Subcopy content={subcopy.childMarkdownRemark.html} />
            </div>
            {!hasCTA && (
              <Hero2CampaignDate
                dateFrom={datePickerFrom}
                dateTo={datePickerTo}
                iconColor={iconColor}
              />
            )}
          </HeroRow>
        )}
        {hasCTA && (
          <HeroRow reverseOnMobile>
            <div>
              {isMobile ? (
                <>
                  <Spacing38 />
                  <Spacing38 />
                </>
              ) : (
                <Spacing48 />
              )}
              <HeroCta delay={delayObj.cta}>
                <Button
                  id={useFieldId(elementId, `register-cta`)}
                  onClick={() => onHeroCTAClick(registerCTA, registerCTALink)}
                  fixedSize>
                  {registerCTA}
                </Button>
                <PhantonButton
                  id={useFieldId(elementId, `singin-cta`)}
                  onClick={() => onHeroCTAClick(signInCTA, signInCTALink)}
                  fixedSize>
                  {signInCTA}
                </PhantonButton>
              </HeroCta>
            </div>
            <Hero2CampaignDate
              dateFrom={datePickerFrom}
              dateTo={datePickerTo}
              iconColor={iconColor}
            />
          </HeroRow>
        )}
      </HeroBody>
      <BottomWhiteCurvedEdge />
    </HeroWrapper>
  )
}

Hero2.propTypes = {
  audiences: PropTypes.string,
  opacity: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.bool,
  ]),
  image: PropTypes.shape({
    altText: PropTypes.string,
    landscape: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({
        aspectRatio: PropTypes.number,
        sizes: PropTypes.string,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        srcSetWebp: PropTypes.string,
      }).isRequired,
    }),
    portrait: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({
        aspectRatio: PropTypes.number,
        sizes: PropTypes.string,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        srcSetWebp: PropTypes.string,
      }).isRequired,
    }),
  }),
  ctaLabel: PropTypes.string,
  ctaUrl: PropTypes.string,
  eyebrow: PropTypes.string,
  isBrandDirectory: PropTypes.bool,
  logo: PropTypes.shape({ code: PropTypes.shape({ code: PropTypes.string }) }),
  pageTemplate: PropTypes.string,
  share: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  }),
  toggleBrandSearch: PropTypes.func,
  video: PropTypes.shape({
    portrait: PropTypes.string,
    landscape: PropTypes.string,
    opacity: PropTypes.number,
    name: PropTypes.string,
  }),
  villageSlug: PropTypes.string,
  village: PropTypes.string,
  campaignSubtitle: PropTypes.string,
  datePickerFrom: PropTypes.string,
  datePickerTo: PropTypes.string,
  id: PropTypes.string,
  contentful_id: PropTypes.string,
  instanceName: PropTypes.string,
  preTitle: PropTypes.string,
  registerCTA: PropTypes.string,
  registerCTALink: PropTypes.string,
  signInCTA: PropTypes.string,
  signInCTALink: PropTypes.string,
  title: PropTypes.string,
  titleSize: PropTypes.bool,
  subcopy: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string,
    }),
  }),
  iconColor: PropTypes.string,
}

Hero2.defaultProps = {
  audiences: '',
  opacity: '',
  content: false,
  ctaLabel: '',
  ctaUrl: '',
  eyebrow: '',
  image: {
    portrait: {
      aspectRatio: 0,
      sizes: '',
      src: '',
      srcSet: '',
      srcSetWebp: '',
    },
    landscape: {
      aspectRatio: 0,
      sizes: '',
      src: '',
      srcSet: '',
      srcSetWebp: '',
    },
    altText: '',
  },
  isBrandDirectory: false,
  logo: { code: { code: '' } },
  pageTemplate: '',
  share: null,
  toggleBrandSearch: null,
  video: { portrait: '', landscape: '', opacity: null },
  villageSlug: '',
  village: '',
  campaignSubtitle: '',
  datePickerFrom: '',
  datePickerTo: '',
  id: '',
  contentful_id: '',
  instanceName: '',
  preTitle: '',
  registerCTA: '',
  registerCTALink: '',
  signInCTA: '',
  signInCTALink: '',
  title: '',
  titleSize: true,
  subcopy: { childMarkdownRemark: { html: '' } },
  iconColor: theme.colors.teal,
}

export default Hero2
