import { Title32 } from 'components/Typography'
import { rem, transitions } from 'polished'
import styled, { css } from 'styled-components'
import theme from 'styles/theme'
import { buttonShadow } from 'styles/sharedStyle'

export const MobileFrameWrapper = styled.div`
  padding: ${rem(50)} ${rem(26)} ${rem(126)} ${rem(26)};
  width: 100%;
  flex-shrink: 0;
  background: rgba(85, 105, 103, 0.2);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9;
  background: ${(props) => props?.bg || 'rgba(85, 105, 103, 0.2)'};
  box-shadow: -3px -3px 150px 0px rgba(255, 255, 255, 0.1) inset,
    3px 3px 150px 0px rgba(66, 66, 66, 0.1) inset;
  backdrop-filter: blur(12px);
  overflow: hidden;
  transition: height 0.5s ease-in-out; /* Add smooth transition for height change */

  /* Define the slide-up animation */
  ${({ isVisible }) =>
    isVisible &&
    css`
      animation: slideUp 0.5s ease-in-out forwards;
    `}

  @keyframes slideUp {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`

export const MobileFrameKeyBoardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  justify-items: center;
  column-gap: ${rem(5)};
  row-gap: ${rem(10)};
  margin: 0 auto;
`

export const MobileCharacterBoxWrapper = styled.button`
  ${transitions(
    'border 0.2s',
    'background 0.2s',
    'box-shadow 0.2s',
    'transform 0.2s'
  )}
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
  border-bottom-color: var(--Primary-Mid-Grey, #bbb7ad);
  background: ${(props) => (props?.bg ? props.bg : theme.colors.pastelTeal)};
  width: ${rem(33)};
  height: ${rem(46)};

  ${(props) =>
    props?.on &&
    `
    ${buttonShadow}
    transform: translateY(-4px);
    background: ${props?.bgOn ? props.bgOn : theme.colors.teal};
    border-color: var(--Primary-Mid-Grey, #bbb7ad);
  `}

  ${(props) =>
    props?.disabled &&
    `
    opacity: 0.25;
  `}
`

export const MobileCharacterWrapper = styled(Title32)`
  color: var(--Bg-Light-1, #fff);
  text-align: center;
  font-family: SangbleuKindgdom-Light, serif;
  font-size: ${rem(23)};
  font-style: normal;
  font-weight: 300;
  cursor: pointer;
  text-transform: uppercase;
`

export default {
  MobileFrameWrapper,
  MobileFrameKeyBoardWrapper,
  MobileCharacterBoxWrapper,
  MobileCharacterWrapper,
}
