import React from 'react'
import MarkdownRenderer from 'components/MarkdownRenderer'
import { Title20, Title32, Title70 } from 'components/Typography'
import styled from 'styled-components'
import { rem } from 'polished'
import { fadeInAnimation } from 'styles/animation'
import { Container } from 'styles/sharedStyle'
import theme from 'styles/theme'

export const ContainerTemplate = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${theme.mediaQueries.mobile} {
    padding: 0 ${theme.space[4]};
  }
`

export const CampaignHeadlineContainer = styled('div')`
  display: flex;
  flex-direction: row;
  color: #ad9667;
  width: 100%;

  ${theme.mediaQueries.small} {
    width: 100%;
    justify-content: center;
  }
`
export const CampaignHeadlineIntroText = styled((props) => (
  <MarkdownRenderer {...props} as="div" />
))`
  p {
    color: ${theme.colors.black};
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 120%;
    font-family: SangbleuKindgdom-Light, serif;
  }
  text-align: center;
  margin: 0 ${theme.space[7]};
  padding-bottom: ${rem(60)};

  ${theme.mediaQueries.small} {
    margin: 0;
  }

  ${theme.mediaQueries.mobile} {
    p {
      font-size: 16px;
    }
  }

  ${theme.mediaQueries.medium} {
    max-width: ${rem(663)};
    padding-bottom: ${rem(97)};
  }
`

export const CampaignHeadlineOfferNumber = styled(Title70)`
  font-size: 48px;
  animation: ${fadeInAnimation} 1s ease-in-out;

  ${theme.mediaQueries.small} {
    font-size: 120px;
  }
`
export const CampaignHeadline = styled(Title70)`
  font-size: 48px;

  ${theme.mediaQueries.small} {
    font-size: 120px;
  }
`

export const CampaignSubHeadline = styled(Title32)`
  text-shadow: 0px 2.649px 2.649px rgba(0, 0, 0, 0.25);
  font-family: SangbleuKindgdom-Light, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  width: 100%;
  margin-right: 50px;
  text-align: right;
  color: ${theme.colors.black};
  letter-spacing: 0;

  ${theme.mediaQueries.small} {
    text-align: center;
    font-family: SangbleuKindgdom-Light, serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    text-shadow: 0 0 black;
    margin-right: 0px;
  }
`

export const TermsAndConditions = styled(Title20)`
  font-size: 12px;
  color: ${theme.colors.black};

  ${theme.mediaQueries.small} {
    font-size: 12px;
  }
`
