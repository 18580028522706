import theme from 'styles/theme'
import styled from 'styled-components'
import { rem, transitions } from 'polished'
import { appearingAnimation } from 'styles/animation'
import { Container as BaseContainer } from 'styles/sharedStyle'

export const Wrapper = styled.div`
  animation: ${appearingAnimation('26px')} 0.75s;
`

export const Container = styled(BaseContainer)`
  ${transitions('padding-top 0.75s')};
  ${(props) => !!props?.bg && `background: #${props.bg};`};
  color: ${theme.colors.white};
  padding: ${rem(229.5)} ${rem(36)} ${rem(97.5)} ${rem(36)};

  ${theme.mediaQueries.small} {
    padding: ${rem(58)};
    padding-top: ${(props) =>
      props?.floatingIslandVisible ? rem(179.63) : rem(55)};
  }
`

export const SectionHeader = styled.div`
  font-size: ${rem(50)};
  font-family: SangBleu-Light;
  font-weight: 300;
  width: 100%;
  display: block;
`

export const ListSection = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: ${rem(20)};
  column-gap: ${rem(30)};

  ${theme.mediaQueries.small} {
    grid-template-columns: repeat(4, 1fr);
  }
`

export const Offer = styled.button`
  ${transitions(
    'background-color 0.2s',
    'border 0.2s',
    'text-decoration 0.2s',
    'color 0.2s'
  )}
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  border: none;
  background: transparent;
  color: ${theme.colors.white};
  padding: ${rem(5)} ${rem(10)};

  &:hover *,
  &:active * {
    text-decoration: underline;
  }

  &:active {
    color: ${theme.colors.pastelTeal};
  }
`

export const OfferBrand = styled.div`
  font-family: BrownStd-Light;
  font-size: ${rem(24)};
  font-weight: 300;
  text-align: left;
  ${theme.mediaQueries.mobile} {
    font-size: ${rem(22)};
  }
`

export const OfferContent = styled.div`
  font-family: BrownStd-Light;
  font-size: ${rem(14)};
  font-weight: 300;

  &:last-child {
    text-decoration: none;
  }
`

export const ScrollToTop = styled.button`
  transform: rotateX(180deg);
  position: fixed;
  background: none;
  border: none;
  right: ${rem(43.5)};
  bottom: ${rem(90)};
`

export default {
  Wrapper,
  Container,
  ListSection,
  SectionHeader,
  Offer,
  OfferBrand,
  OfferContent,
  ScrollToTop,
}
