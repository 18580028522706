import { useState, useEffect, useRef, useCallback } from 'react'

const OfferScrollSection = (offers, isMobile) => {
  const [currentOffers, setCurrentOffers] = useState([])
  const [isScrolling, setIsScrolling] = useState(false)
  const [stopAnimation, setStopAnimation] = useState(false)
  const [showArrow, setShowArrow] = useState(true)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [showFullGrid, setShowFullGrid] = useState(false)
  const [showFloatingIsland, setShowFloatingIsland] = useState(false)

  const chunkSize = isMobile ? 2 : 4 // Mobile: 2 items per row, Desktop: 4 items per row
  const rowsToShow = 2
  const scrollTimeoutRef = useRef(null)
  const currentIndex = useRef(0)

  // Flatten the offers into a single array for easier slicing
  const createChunks = (array, size) => {
    const chunks = []
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size))
    }
    return chunks
  }

  const openAllOffers = () => {
    setCurrentOffers(offers)
    setStopAnimation(true)
  }

  // Load the initial set of offers
  useEffect(() => {
    const initialChunks = createChunks(offers, chunkSize)
    const initialOffers = initialChunks.slice(0, rowsToShow).flat()
    setCurrentOffers(initialOffers)
    currentIndex.current = (currentIndex.current + 1) % initialChunks.length
  }, [offers, chunkSize, rowsToShow])

  // Handle scroll to trigger loading more offers
  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY + window.innerHeight
    const pagePosition =
      document.documentElement.scrollHeight - (isMobile ? 1100 : 1000)

    setIsScrolling(true)
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current)
    }

    scrollTimeoutRef.current = setTimeout(() => {
      setIsScrolling(false)
      if (scrollPosition > pagePosition && showArrow) {
        setShowArrow(false)
        setIsTransitioning(true)
        setTimeout(() => {
          openAllOffers()
          setShowFullGrid(true)
          setIsTransitioning(false)
        }, 500)
      }
    }, 200)
  }, [isMobile, showArrow])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current)
      }
    }
  }, [handleScroll])

  // Update offers based on scroll
  useEffect(() => {
    if (!isScrolling && !stopAnimation) {
      const interval = setInterval(() => {
        const chunks = createChunks(offers, chunkSize)
        const totalChunks = chunks.length

        // Generate the list of offers to show
        let nextOffers = []
        for (let i = 0; i < rowsToShow; i += 1) {
          const chunkIndex = (currentIndex.current + i) % totalChunks
          nextOffers = [...nextOffers, ...chunks[chunkIndex]]
        }

        setCurrentOffers(nextOffers)
        currentIndex.current = (currentIndex.current + 1) % totalChunks
      }, 6000)

      return () => clearInterval(interval)
    }
    return () => {}
  }, [offers, chunkSize, stopAnimation, rowsToShow])

  const openAllOffersClick = () => {
    setShowArrow(false)
    setIsTransitioning(true)
    setTimeout(() => {
      openAllOffers()
      setShowFullGrid(true)
      setIsTransitioning(false)
    }, 500)
  }

  useEffect(() => {
    if (showFullGrid) {
      const timer = setTimeout(() => {
        setShowFloatingIsland(true)
      }, 3000)

      return () => clearTimeout(timer)
    }
    return () => {}
  }, [showFullGrid])

  const hideFloatingIsland = () => {
    setShowFloatingIsland(false)
  }

  const openFloatingIsland = () => {
    setShowFloatingIsland(true)
  }

  const newOfferProps = {
    showArrow,
    isTransitioning,
    showFullGrid,
    openAllOffersClick,
  }

  const islandMethods = {
    hideFloatingIsland,
    openFloatingIsland,
  }

  return {
    currentOffers,
    newOfferProps,
    showFloatingIsland,
    islandMethods,
  }
}

export default OfferScrollSection
