import theme from 'styles/theme'
import styled, { css } from 'styled-components'
import { Container } from 'styles/sharedStyle'
import { rem } from 'polished'

// eslint-disable-next-line import/prefer-default-export
export const GridContainer = styled(Container)`
  z-index: 1;
  display: grid;
  margin-top: ${rem(35)};
  padding-top: ${rem(55)};
  gap: 15px;
  grid-template-columns: repeat(2, 1fr); /* 2 columns for mobile */
  position: relative;
  max-width: ${({ showFullGrid }) => (showFullGrid ? '1200px' : '100%')};

  ${({ showFullGrid }) =>
    showFullGrid &&
    css`
      ${theme.mediaQueries.small} {
        grid-template-columns: repeat(2, 1fr); /* 4 columns for web */
        gap: 29px;
      }
    `};
  ${theme.mediaQueries.mobile} {
    padding-top: ${rem(75)};
    padding-left: ${theme.space[4]};
    padding-right: ${theme.space[4]};
  }
  ${theme.mediaQueries.medium} {
    grid-template-columns: repeat(4, 1fr); /* 4 columns for web */
    gap: 29px;
    transition: opacity 0.5s ease, transform 1s ease, max-width 1s ease;
    opacity: ${({ isTransitioning }) => (isTransitioning ? 0 : 1)};
    transform: ${({ isTransitioning }) =>
      isTransitioning ? 'scale(0.9)' : 'scale(1)'};
  }
`

export const GridFooterGradient = styled(Container)`
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 255, 255) 100%
  );
  height: 308px;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  margin-top: -308px;
  max-width: 100%;
  ${theme.mediaQueries.mobile} {
    height: 170.687px;
    margin-top: -170.687px;
  }
`
