import { useState, useEffect, useCallback, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { debounceFn, useThrottle } from './useOptimizers'

export const SCROLL_DIRECTIONS = {
  up: 'UP',
  down: 'DOWN',
  iddle: 'IDDLE',
}

const defaultOptions = {
  targetElement: null,
  delayAmount: {
    scroll: 150,
    iddle: 1000,
  },
  noDelay: false,
  onIddle: (param = null) => param,
  onScroll: (param = null) => param,
}
/**
 *
 * @param {typeof defaultOptions=} options
 */
export default (options = defaultOptions) => {
  const {
    targetElement = defaultOptions.targetElement,
    delayAmount = defaultOptions.delayAmount,
    noDelay = defaultOptions.noDelay,
    onIddle,
    onScroll,
  } = options
  const lastScrollRef = useRef(0)
  const [isScrolling, setIsScrolling] = useState(false)
  const [scrollDirection, setScrollDirection] = useState(
    SCROLL_DIRECTIONS.iddle
  )
  const [lastScrollDirection, setLastScrollDirection] = useState(
    SCROLL_DIRECTIONS.iddle
  )

  const isSafariMobile =
    isMobile && /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  const onSafariIddle = useCallback(() => {
    setIsScrolling(() => false)
    setScrollDirection(() => SCROLL_DIRECTIONS.iddle)
    if (onIddle) onIddle()
  }, [])

  const deboucedSafariIddle = debounceFn(
    onSafariIddle,
    delayAmount.scroll + 150
  )

  const onScrollHandler = useCallback(
    (event) => {
      const scrollPosition =
        window.scrollY || window.document.documentElement.scrollTop

      setIsScrolling(true)

      if (onScroll) onScroll(event)

      if (lastScrollRef.current) {
        const currentScrollDirection =
          lastScrollRef.current > scrollPosition
            ? SCROLL_DIRECTIONS.up
            : SCROLL_DIRECTIONS.down

        setScrollDirection(() => currentScrollDirection)
        setLastScrollDirection(() => currentScrollDirection)
      }

      lastScrollRef.current = scrollPosition

      if (isSafariMobile) {
        deboucedSafariIddle()
      }
    },
    [onScroll]
  )

  const onIddleHandler = useCallback(() => {
    setIsScrolling(() => false)
    setScrollDirection(() => SCROLL_DIRECTIONS.iddle)

    if (onIddle) onIddle()
  }, [onIddle])

  const throttledScroll = noDelay
    ? onScrollHandler
    : useThrottle(onScrollHandler, delayAmount.scroll)
  const deboucedIddle = debounceFn(onIddleHandler, delayAmount.iddle)

  useEffect(() => {
    if (targetElement || (window && window?.document)) {
      const element = targetElement ?? window
      element.addEventListener('scroll', throttledScroll)
      element.addEventListener('scrollend', deboucedIddle)

      return () => {
        element.removeEventListener('scroll', throttledScroll)
        element.removeEventListener('scrollend', deboucedIddle)
      }
    }

    return () => null
  }, [targetElement])

  return { isScrolling, scrollDirection, lastScrollDirection }
}
