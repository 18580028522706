import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { OffersContextDefinition } from 'contexts/offers'
import NewOfferCard from 'components/NewOfferCard/NewOfferCard'
import VisitCard from 'components/VisitCard/VisitCard'
import { isMobile } from 'react-device-detect'
import NewOfferCardDetail from 'components/NewOfferCardDetail/NewOfferCardDetail'
import NewOfferCardDetailMobile from 'components/NewOfferCardDetail/mobile/NewOfferCardDetailMobile'
import useOfferAnimations from 'hooks/useOfferAnimations'
import useOfferCardDetail from './useOfferCardDetail'
import { GridContainer, GridFooterGradient } from './NewOfferGrid.styles'

const NewOfferGrid = ({
  heroCTA1,
  heroCTA1Link,
  visitCardProps,
  url,
  settings,
  village,
  nodeLocale,
  newOfferProps,
  islandMethods,
}) => {
  const { offers } = useContext(OffersContextDefinition)
  const {
    isCardSelected,
    selectedOffer,
    showBigCard,
    gridRow,
    setIsCardSelected,
    setIndexSelected,
    close,
  } = useOfferCardDetail(offers, isMobile)
  const redirectToVisit = () => {
    window.location.href = url
  }

  const {
    animate,
    animate2,
    animateOut,
    animateOut2,
    updatedOffers,
    isFirstColumn,
  } = useOfferAnimations(offers, newOfferProps)

  return (
    <>
      <GridContainer
        isCardSelected={isCardSelected}
        isTransitioning={newOfferProps.isTransitioning}
        showFullGrid={newOfferProps.showFullGrid}
        id="grid-offers">
        {offers.map((offer, index) => {
          const columnIndex = index % (isMobile ? 2 : 4)
          const shouldShowVisitCard = index + (1 % 20) === 0 || index + 1 === 10
          const delay = columnIndex * (columnIndex < 2 ? 0.3 : 0.2)
          return (
            <>
              {isFirstColumn(index) || !newOfferProps.showArrow ? (
                <NewOfferCard
                  animate={animate}
                  animateOut={animateOut}
                  village={village}
                  delay={delay}
                  showFullGrid={newOfferProps.showFullGrid}
                  key={`${offer.id}-1`}
                  {...offer}
                  columnIndex={columnIndex}
                  heroCTA1={heroCTA1}
                  heroCTA1Link={heroCTA1Link}
                  settings={settings}
                  setIsCardSelected={setIsCardSelected}
                  index={index}
                  setIndexSelected={setIndexSelected}
                  isModalOpen={selectedOffer && selectedOffer.id === offer.id}
                  isCardSelected={isCardSelected}
                />
              ) : (
                <NewOfferCard
                  animate={animate2}
                  animateOut={animateOut2}
                  village={village}
                  delay={delay}
                  showFullGrid={newOfferProps.showFullGrid}
                  key={
                    updatedOffers.length === 0
                      ? offer.id
                      : updatedOffers[index].id
                  }
                  {...(updatedOffers.length === 0
                    ? { ...offer }
                    : { ...updatedOffers[index] })}
                  columnIndex={columnIndex}
                  heroCTA1={heroCTA1}
                  heroCTA1Link={heroCTA1Link}
                  settings={settings}
                  setIsCardSelected={setIsCardSelected}
                  index={index}
                  setIndexSelected={setIndexSelected}
                  isModalOpen={
                    selectedOffer &&
                    selectedOffer.id ===
                      (updatedOffers.length === 0
                        ? offer.id
                        : updatedOffers[index].id)
                  }
                  isCardSelected={isCardSelected}
                />
              )}

              {shouldShowVisitCard ? (
                <VisitCard
                  animate={animate}
                  delay={delay}
                  key={`visit-card-${offer.id}`}
                  {...visitCardProps}
                  onClick={() => redirectToVisit()}
                  isCardSelected={isCardSelected}
                />
              ) : null}
            </>
          )
        })}
        {showBigCard && (
          <NewOfferCardDetailMobile
            gridRow={gridRow}
            offer={selectedOffer}
            settings={settings}
            heroCTA1={heroCTA1}
            heroCTA1Link={heroCTA1Link}
            village={village}
            nodeLocale={nodeLocale}
            close={close}
          />
        )}

        {isCardSelected && !isMobile && (
          <NewOfferCardDetail
            isModalOpen={selectedOffer && isCardSelected}
            islandMethods={islandMethods}
            close={close}
            village={village}
            nodeLocale={nodeLocale}
            settings={settings}
            {...selectedOffer}
          />
        )}
      </GridContainer>
      <GridFooterGradient visible={newOfferProps.showArrow} />
    </>
  )
}

NewOfferGrid.propTypes = {
  islandMethods: PropTypes.shape({
    hideFloatingIsland: PropTypes.func,
    openFloatingIsland: PropTypes.func,
  }).isRequired,
  visitCardProps: PropTypes.shape({}),
  heroCTA1: PropTypes.string,
  heroCTA1Link: PropTypes.string,
  url: '',
  newOfferProps: PropTypes.shape({
    showArrow: PropTypes.bool,
    isTransitioning: PropTypes.bool,
    showFullGrid: PropTypes.bool,
    openAllOffersClick: PropTypes.func,
  }),
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      floatIslandBG: PropTypes.string,
      id: PropTypes.string,
      offerCardColour2: PropTypes.string,
      offerCardPrimaryBG: PropTypes.string,
      offerGridArrowColour: PropTypes.string,
      offerGridListViewBG: PropTypes.string,
      offerGridMasterHL: PropTypes.string,
      updatedAt: PropTypes.string,
      visitCTA: PropTypes.string,
      visitTitle: PropTypes.shape({
        childMarkdownRemark: PropTypes.shape({
          html: PropTypes.string,
        }),
      }),
      visitURL: PropTypes.string,
    })
  ),
  village: PropTypes.shape({
    villageSlug: PropTypes.string,
  }),
  nodeLocale: PropTypes.string,
}

NewOfferGrid.defaultProps = {
  visitCardProps: {},
  heroCTA1: '',
  heroCTA1Link: '',
  url: '',
  newOfferProps: {
    showArrow: true,
    isTransitioning: false,
    showFullGrid: false,
    openAllOffersClick: () => {},
  },
  settings: [
    {
      floatIslandBG: '',
      id: '',
      offerCardColour2: '',
      offerCardPrimaryBG: '',
      offerGridArrowColour: '',
      offerGridListViewBG: '',
      offerGridMasterHL: '',
      updatedAt: '',
      visitCTA: '',
      visitTitle: { childMarkdownRemark: { html: '' } },
      visitURL: '',
    },
  ],
  village: {
    villageSlug: '',
  },
  nodeLocale: '',
}

export default NewOfferGrid
